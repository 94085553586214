import Figurka from "../../images/Figurka.webp";
import DiscountImg from "../../images/discount.svg";

const Discount = () => {
  return (
    <div className="mb-[100px] mt-[84px] flex justify-center">
      <div className="w-[832px] h-fit bg-white rounded-[10px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.10)] lg:px-[50px] px-[25px] py-[30px] hidden lg:block">
        <div className="flex justify-center">
          <h2 className="lg:text-[40px] text-[21px] font-black text-[#5F5F59] text-center mb-[29px] font-nunito">
            Надаємо знижку 10% <br />
            для пільгових категорій:
          </h2>
        </div>
        <div className="flex items-center">
          <div className="lg:mr-[46px]">
            <img
              src={Figurka}
              alt="Figurka"
              className="h-[265px] lg:block hidden"
            />
          </div>
          <div className="flex">
            <ul className="flex flex-col gap-y-6 font-montserrat">
              <li className="flex items-center gap-x-[18px]">
                <div className="w-[33px] h-[33px] bg-white rounded-[3px] shadow-[0px_1px_9px_0px_rgba(0,0,0,0.25)] flex justify-center items-center">
                  <div className="w-[27px] h-[27px] rounded-[3px] bg-[#FFD6BC]"></div>
                </div>
                <span className="text-[16px] font-medium text-[#5F5F59]">
                  Діти зі статусом внутрішньо переміщених осіб
                </span>
              </li>
              <li className="flex items-center gap-x-[18px]">
                <div className="w-[33px] h-[33px] bg-white rounded-[3px] shadow-[0px_1px_9px_0px_rgba(0,0,0,0.25)] flex justify-center items-center">
                  <div className="w-[27px] h-[27px] rounded-[3px] bg-[#FFD6BC]"></div>
                </div>
                <span className="text-[16px] font-medium text-[#5F5F59]">
                  Діти, один із батьків яких є військослужбовцем ЗСУ
                </span>
              </li>
              <li className="flex items-center gap-x-[18px]">
                <div className="w-[33px] h-[33px] bg-white rounded-[3px] shadow-[0px_1px_9px_0px_rgba(0,0,0,0.25)] flex justify-center items-center">
                  <div className="w-[27px] h-[27px] rounded-[3px] bg-[#FFD6BC]"></div>
                </div>
                <span className="text-[16px] font-medium text-[#5F5F59]">
                  Діти-сироти й діти, які втратили одного з батьків
                </span>
              </li>
              <li className="flex items-center gap-x-[18px]">
                <div className="w-[33px] h-[33px] bg-white rounded-[3px] shadow-[0px_1px_9px_0px_rgba(0,0,0,0.25)] flex justify-center items-center">
                  <div className="w-[27px] h-[27px] rounded-[3px] bg-[#FFD6BC]"></div>
                </div>
                <span className="text-[16px] font-medium text-[#5F5F59]">
                  Діти з багатодітних родин (3 або більше дітей віком до 18
                  років)
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="translate-x-[-85px] translate-y-[-25px]">
        <img
          src={DiscountImg}
          alt="Birka"
          className="h-[365px] lg:block hidden"
        />
      </div>
      {/* Mobile */}
      <div className="block lg:hidden">
        <h1 className="text-[#5F5F59] font-nunito font-black text-[24px] text-center mb-[60px]">
          Надаємо знижку 10% для пільгових категорій:
        </h1>
        <div className="!h-[400px]">
          <div className="bg-white flex justify-center items-center w-[278px] h-[368px] p-[25px] rounded-[10px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.10)]">
            <ul className="flex flex-col justify-center items-center gap-y-[28px]">
              <li className="flex items-center gap-4">
                <div className="w-[33px] h-[33px] bg-white rounded-[3px] shadow-[0px_1px_9px_0px_rgba(0,0,0,0.25)] p-0.5 flex justify-center items-center">
                  <div className="w-[27px] h-[27px] rounded-[3px] bg-[#FFD6BC]"></div>
                </div>
                <div className="text-[#5F5F59] text-[15px] font-montserrat font-medium leading-4">
                  Діти зі статусом внутрішньо переміщених осіб
                </div>
              </li>
              <li className="flex items-center gap-4">
                <div className="w-[33px] h-[33px] bg-white rounded-[3px] shadow-[0px_1px_9px_0px_rgba(0,0,0,0.25)] p-0.5 flex justify-center items-center">
                  <div className="w-[27px] h-[27px] rounded-[3px] bg-[#FFD6BC]"></div>
                </div>
                <div className="text-[#5F5F59] text-[15px] font-montserrat font-medium leading-4">
                  Діти, один із батьків яких є військослужбовцем ЗСУ
                </div>
              </li>
              <li className="flex items-center gap-4">
                <div className="w-[33px] h-[33px] bg-white rounded-[3px] shadow-[0px_1px_9px_0px_rgba(0,0,0,0.25)] p-0.5 flex justify-center items-center">
                  <div className="w-[27px] h-[27px] rounded-[3px] bg-[#FFD6BC]"></div>
                </div>
                <div className="text-[#5F5F59] text-[15px] font-montserrat font-medium leading-4">
                  Діти-сироти й діти, які втратили одного з батьків
                </div>
              </li>
              <li className="flex items-center gap-4">
                <div className="w-[33px] h-[33px] bg-white rounded-[3px] shadow-[0px_1px_9px_0px_rgba(0,0,0,0.25)] p-0.5 flex justify-center items-center">
                  <div className="w-[27px] h-[27px] rounded-[3px] bg-[#FFD6BC]"></div>
                </div>
                <div className="text-[#5F5F59] text-[15px] font-montserrat font-medium leading-4">
                  Діти з багатодітних родин (3 або більше дітей віком до 18
                  років)
                </div>
              </li>
            </ul>
          </div>
          <div className="translate-x-[241px] translate-y-[-379px] w-[139px] overflow-hidden">
            <img src={DiscountImg} alt="Birka" className="h-[161px]" />
          </div>
          <div className="translate-x-[295px] translate-y-[-336px] w-[52px]">
            <img src={Figurka} alt="Figurka" className="h-[175px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discount;
