import axios from "axios";
import { useEffect, useState } from "react";
import { Collapse } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import SovaLitleMoodrik from "../../../images/SovaLitleMoodrik.webp";
import { ENDPOINTS } from '../../../api/apiEndpoints';
interface FaqType {
  id: number;
  question: string;
  answer: string;
}

const Faq = () => {
  const [faqData, setFaqData] = useState<FaqType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    axios
      .get(ENDPOINTS.getFAQ)
      .then((response) => {
        setFaqData(response.data.faq);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Помилка отримання даних:", error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="flex flex-col justify-center items-center bg-gradient-to-b from-[#FDE6BA] to-[#FFDA94]">
      <div className="max-w-[1390px] w-full px-[50px]">
        <div className="flex justify-between items-center">
          <div className="lg:text-center">
            <span className="lg:text-[40px] text-[20px] font-black font-nunito text-[#5F5F59] ">
              ВІДПОВІДІ <br /> НА ВАШІ ЧАСТІ ЗАПИТАННЯ
            </span>
          </div>
          <div>
            <img
              src={SovaLitleMoodrik}
              alt="SovaLitleMoodrik"
              className="lg:h-[150px] lg:w-[300px] w-[183px]"
            />
          </div>
        </div>
        {loading ? (
          <div className="flex justify-center items-center my-8">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 42 }} spin />}
            />
          </div>
        ) : (
          <div className="flex flex-col gap-y-[25px] mt-[87px] mb-[82px]">
            {faqData.length > 0 &&
              faqData.map((el: FaqType, index: number) => (
                <div key={index}>
                  <Collapse defaultActiveKey={["0"]}>
                    <Collapse.Panel
                      className="lg:text-[24px] text-[14px] font-montserrat font-medium text-[#5F5F59]"
                      header={el.question}
                      key={index}
                    >
                      <p className="lg:text-[24px] text-[14px] font-montserrat font-medium text-[#5F5F59]">
                        {el.answer}
                      </p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="bg-[#A7A7A7] w-full flex justify-center items-center py-[32px]">
        <span className="text-[#FFFFFF] lg:text-[24px] text-[14px] text-wrap text-center font-montserrat font-extrabold ">
          Залиште вашу заявку, щоб дізнатися розклад занять
        </span>
      </div>
    </div>
  );
};

export default Faq;
