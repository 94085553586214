import React, { useEffect, useState } from "react";
import SovaLogoLesson from "../../../images/Sova_logo_lesson.webp";
import Right from "../../../images/Right.webp";
import Left from "../../../images/Left.webp";
import axios from "axios";
import { ENDPOINTS } from "../../../api/apiEndpoints";

interface Day {
  label: string;
  date: Date;
}

interface LessonEvent {
  titleLesson: string;
  lesson_days: any;
  date: string;
  day_str: string;
  times: string[];
  title: string;
  photo: any;
}

const Schedule = () => {
  const [events, setEvents] = useState<LessonEvent[]>([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [grid, setGrid] = useState<
    {
      date: Date | null;
      events: {
        isGroupClass: any;
        title: string;
        teacher: string;
        photo: any;
      }[];
    }[][]
  >(
    Array.from({ length: 12 }, () =>
      Array.from({ length: 7 }, () => ({ date: null, events: [] }))
    )
  );

  useEffect(() => {
    axios
      .get(ENDPOINTS.getSchedule)
      .then((response) => {
        setEvents(response.data.schedule);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Помилка отримання даних:", error);
      });
  }, []);

  useEffect(() => {
    // Update grid when currentDate changes
    if (events && events.length > 0) {
      // Reset grid
      const updatedGrid: {
        date: Date | null;
        events: {
          title: string;
          teacher: string;
          photo: any;
          isGroupClass: any;
        }[];
      }[][] = Array.from({ length: 12 }, () =>
        Array.from({ length: 7 }, () => ({ date: null, events: [] }))
      );

      // Populate grid with events
      events.forEach((event) => {
        if (event.lesson_days && event.lesson_days.length > 0) {
          event.lesson_days.forEach((lessonDay: any) => {
            const eventDate = new Date(
              lessonDay.date + "T" + lessonDay.startTime
            );
            const columnIndex =
              eventDate.getDay() === 0 ? 6 : eventDate.getDay() - 1;
            const rowIndex = eventDate.getHours() - 8;
            if (
              rowIndex >= 0 &&
              rowIndex < 12 &&
              columnIndex >= 0 &&
              columnIndex < 7 &&
              isSameWeek(currentDate, eventDate)
            ) {
              updatedGrid[rowIndex][columnIndex].date = eventDate;
              updatedGrid[rowIndex][columnIndex].events.push({
                title: event.titleLesson,
                teacher: lessonDay.teacher.full_name,
                photo: event.photo,
                isGroupClass: event.lesson_days.isGroupClass,
              });
            }
          });
        }
      });

      // Update grid state
      setGrid(updatedGrid);
    }
  }, [events, currentDate]);

  // Function to check if two dates are in the same week
  function isSameWeek(date1: Date, date2: Date) {
    const firstDayOfWeek = new Date(date1);
    firstDayOfWeek.setDate(
      firstDayOfWeek.getDate() - firstDayOfWeek.getDay() + 1
    );
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);
    return date2 >= firstDayOfWeek && date2 <= lastDayOfWeek;
  }

  const getWeekDays = (date: Date): Day[] => {
    const weekDays: Day[] = [];
    const startDate = new Date(date);
    startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startDate);
      currentDate.setDate(currentDate.getDate() + i);
      weekDays.push({
        label: `${currentDate.toLocaleDateString()}`,
        date: currentDate,
      });
    }
    return weekDays;
  };

  const previousWeek = () => {
    const previousWeekDate = new Date(currentDate);
    previousWeekDate.setDate(previousWeekDate.getDate() - 7);
    setCurrentDate(previousWeekDate);
  };

  const nextWeek = () => {
    const nextWeekDate = new Date(currentDate);
    nextWeekDate.setDate(nextWeekDate.getDate() + 7);
    setCurrentDate(nextWeekDate);
  };

  const previousDay = () => {
    const previousWeekDate = new Date(currentDate);
    previousWeekDate.setDate(previousWeekDate.getDate() - 1);
    setCurrentDate(previousWeekDate);
  };

  const nextDay = () => {
    const nextWeekDate = new Date(currentDate);
    nextWeekDate.setDate(nextWeekDate.getDate() + 1);
    setCurrentDate(nextWeekDate);
  };

  const currentDayOfWeek = new Date(currentDate).getDay() - 1;
  const todayIndex = new Date().getDay();
  return (
    <div>
      <div className="flex justify-center">
        <div className="max-w-[1390px] w-full px-[50px]">
          <div className="flex items-center lg:justify-end justify-center mt-[98px]">
            <div className="flex lg:flex-row flex-col-reverse justify-between items-end w-2/3">
              <div>
                <div className="flex justify-center items-center p-[9px] lg:h-[72px] h-[32px] lg:w-[394px] w-[224px] bg-gradient-to-b from-[#FDE68A] to-[#FDE072] rounded-md shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)]">
                  <span className="lg:text-[40px] text-[20px] text-white font-black">
                    НАШ РОЗКЛАД
                  </span>
                </div>
                <div className="flex justify-center items-center mt-[17px]">
                  <span className="text-[#484C4C] text-[12px]">
                    за період {getWeekDays(currentDate)[0].label} -{" "}
                    {getWeekDays(currentDate)[6].label}
                  </span>
                </div>
              </div>
              <div>
                <img
                  src={SovaLogoLesson}
                  alt="SovaLogoLesson"
                  className="lg:h-[150px] lg:w-[300px] w-[174px] h-[88px] lg:mb-0 mb-10"
                />
              </div>
            </div>
          </div>
          <div className="mt-[30px] mb-[130px]">
            {/* desctop */}
            <div className="mb-[20px] lg:flex hidden justify-between">
              <div className="flex items-center gap-2">
                <button onClick={previousWeek}>
                  <img src={Left} alt="Left" className="w-[53px]" />
                </button>
                <span>Минулий тиждень</span>
              </div>
              <div className="flex items-center gap-2">
                <span>Наступний тиждень</span>
                <button onClick={nextWeek}>
                  <img src={Right} alt="Right" className="w-[53px]" />
                </button>
              </div>
            </div>
            {/* mobile */}
            <div className="mb-[20px] flex lg:hidden justify-between">
              <div className="flex items-center gap-2">
                <button onClick={previousDay}>
                  <img src={Left} alt="Left" className="w-[23px]" />
                </button>
                <span>попередній день</span>
              </div>
              <div className="flex items-center gap-2">
                <span>наступний день</span>
                <button onClick={nextDay}>
                  <img src={Right} alt="Right" className="w-[23px]" />
                </button>
              </div>
            </div>
            <div className="w-full bg-gradient-to-b from-[#FDE790] to-[#FCE071] min-h-[900px] h-full p-[5px] rounded-md">
              <div className="bg-white w-full h-full rounded-[5px] py-[21px]">
                <div>
                  <div className="flex flex-col items-center ">
                    {/* Desctop */}
                    <div className="lg:flex hidden justify-center gap-1">
                      {getWeekDays(currentDate).map((day, index) => (
                        <div className="flex flex-col" key={index}>
                          <div className="bg-[#FEE891] flex items-center justify-center min-w-[160px]">
                            <span className="text-[#484C4C] text-[16px] font-bold uppercase">
                              {
                                [
                                  "Понеділок",
                                  "Вівторок",
                                  "Середа",
                                  "Четвер",
                                  "Пятниця",
                                  "Субота",
                                  "Неділя",
                                ][index]
                              }
                            </span>
                          </div>
                          <div className="bg-[#FDEDAF] flex flex-col items-center">
                            <h2 className="text-[#484C4C] text-[12px]">
                              {day.label}
                            </h2>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* Mobile */}
                    <div className="flex lg:hidden justify-center gap-1">
                      <div className="flex flex-col" key={currentDayOfWeek}>
                        <div className="bg-[#FEE891] flex items-center justify-center min-w-[160px]">
                          <span className="text-[#484C4C] text-[16px] font-bold uppercase">
                            {
                              [
                                "Понеділок",
                                "Вівторок",
                                "Середа",
                                "Четвер",
                                "П'ятниця",
                                "Субота",
                                "Неділя",
                              ][currentDayOfWeek]
                            }
                          </span>
                        </div>
                        <div className="bg-[#FDEDAF] flex flex-col items-center">
                          <h2 className="text-[#484C4C] text-[12px]">
                            {getWeekDays(currentDate)[currentDayOfWeek] &&
                              getWeekDays(currentDate)[currentDayOfWeek].label}
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between px-[14px]">
                      <div className="flex flex-col gap-[45px]">
                        {[...Array(13)].map((_, index) => {
                          const hour = index + 8;
                          const time = `${hour.toString().padStart(2, "0")}:00`;
                          return (
                            <div
                              className="text-[#484C4C] text-[16px] font-semibold flex items-center gap-2"
                              key={index}
                            >
                              {time}
                              <div className="bg-[#FFDFA1] w-[10px] h-[10px] rounded-full"></div>
                            </div>
                          );
                        })}
                      </div>
                      {/* Desctop */}
                      <div className="lg:flex hidden flex-col gap-1 mt-3">
                        {grid.map((row, rowIndex) => (
                          <div key={rowIndex} className="flex gap-1">
                            {row.map((cell, colIndex) => (
                              <div
                                key={`${rowIndex}-${colIndex}`}
                                className="flex flex-col items-center justify-center w-[160px] h-[65px]"
                                style={{
                                  backgroundColor: cell.events.length
                                    ? cell.events[0].isGroupClass
                                      ? "#FFDFA1"
                                      : "#FFD6BC"
                                    : "#EFEFEF",
                                }}
                              >
                                {cell.events.map((event, index) => (
                                  <div
                                    key={index}
                                    className="flex justify-center items-center gap-x-[15px] px-[10px] py-[5px]"
                                  >
                                    <div className="w-[30px]">
                                      <img
                                        src={`https://mmudryk.com.ua/${event.photo}`}
                                        alt={event.photo.id + 1}
                                        className="w-[30px] h-[30px] rounded-full border border-white object-contain"
                                      />
                                    </div>
                                    <div className="flex flex-col w-[100px]">
                                      <span className="text-[10px] text-[#484C4C] leading-4 font-semibold font-montserrat">
                                        {event.title}
                                      </span>
                                      <div className="w-full h-[1px] bg-[#484C4C]" />
                                      <span className="text-[10px] text-[#484C4C] leading-3 font-montserrat">
                                        Педагог:
                                        <br />
                                        {event.teacher}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                      {/* Mobile */}
                      <div className="flex lg:hidden flex-col gap-1 mt-3 pr-[53px]">
                        {grid.map((row, rowIndex) => (
                          <div key={rowIndex} className="flex gap-1">
                            {row[todayIndex] && ( // Перевіряємо, чи існує стовпець з індексом поточного дня
                              <div
                                key={`${rowIndex}-${todayIndex}`}
                                className="flex flex-col items-center justify-center w-[160px] h-[65px]"
                                style={{
                                  backgroundColor: row[todayIndex].events.length
                                    ? row[todayIndex].events[0].isGroupClass
                                      ? "#FFDFA1"
                                      : "#FFD6BC"
                                    : "#EFEFEF",
                                }}
                              >
                                {row[todayIndex].events.map((event, index) => (
                                  <div
                                    key={index}
                                    className="flex justify-center items-center gap-x-[15px] px-[10px] py-[5px]"
                                  >
                                    <div className="w-[30px]">
                                      <img
                                        src={`https://mmudryk.com.ua/${event.photo}`}
                                        alt={event.photo.id + 1}
                                        className="w-[30px] h-[30px] rounded-full border border-white object-contain"
                                      />
                                    </div>
                                    <div className="flex flex-col w-[100px]">
                                      <span className="text-[12px] text-[#484C4C] leading-4 font-semibold font-montserrat">
                                        {event.title}
                                      </span>
                                      <div className="w-full h-[1px] bg-[#484C4C]" />
                                      <span className="text-[10px] text-[#484C4C] leading-3 font-montserrat">
                                        Педагог:
                                        <br />
                                        {event.teacher}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="lg:flex hidden flex-col gap-[45px]">
                        {[...Array(13)].map((_, index) => {
                          const hour = index + 8;
                          const time = `${hour.toString().padStart(2, "0")}:00`;
                          return (
                            <div
                              className="text-[#484C4C] text-[16px] font-semibold flex items-center gap-2"
                              key={index}
                            >
                              <div className="bg-[#FFDFA1] w-[10px] h-[10px] rounded-full"></div>
                              {time}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="lg:px-[90px] mt-6 mb-4 flex justify-center">
                    <div className="bg-[#FAFAFA] min-h-[63px] lg:w-full w-[306px] rounded-[5px] flex justify-center items-center px-[30px] py-[10px] shadow-[0px_4px_8px_0px_rgba(0,0,0,0.05)]">
                      <div className="flex lg:flex-row flex-col lg:items-center items-start lg:gap-[48px] gap-2">
                        <div>
                          <span className="text-[#484C4C] text-[14px] font-medium">
                            Класифікація розкладу:
                          </span>
                        </div>
                        <div className="flex lg:flex-row flex-col lg:items-center items-start gap-[35px]">
                          <div className="flex items-center gap-[17px]">
                            <div className="bg-[#EFEFEF] border border-[#D2D2D2] w-9 h-9" />
                            <span className="text-[#484C4C] text-[16px] font-semibold">
                              вільний час
                            </span>
                          </div>
                          <div className="flex items-center gap-[17px]">
                            <div className="bg-[#FFDFA1] border border-[#D2D2D2] w-9 h-9" />
                            <span className="text-[#484C4C] text-[16px] font-semibold">
                              групове заняття
                            </span>
                          </div>
                          <div className="flex items-center gap-[17px]">
                            <div className="bg-[#FFD6BC] border border-[#D2D2D2] w-9 h-9" />
                            <span className="text-[#484C4C] text-[16px] font-semibold">
                              індивідуальне заняття
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#A7A7A7] w-full flex justify-center items-center py-[32px]">
        <span className="text-[#FFFFFF] lg:text-[24px] text-[14px] font-extrabold ">
          Залиште вашу заявку, щоб дізнатися розклад занять
        </span>
      </div>
    </div>
  );
};

export default Schedule;
