import FamilySova from "../../images/FamilySova.svg";

const Parents = () => {
  return (
    <div className="mt-[70px] mb-[63px]">
      <div className="lg:flex items-center lg:flex-row flex-col justify-between mb-[48px]">
        <div>
          <span
            id="text"
            className="lg:text-[40px] text-[24px] font-black text-white lg:pr-[28px] font-nunito"
          >
            БАТЬКАМ
          </span>
        </div>
        <div className="flex items-center">
          <div className="w-[8px] h-[8px] rounded-full bg-white lg:block hidden" />
          <div className="w-[504px] bg-white h-[2px] lg:block hidden" />
          <div className="lg:flex hidden justify-center items-center bg-white rounded-[5px] lg:w-[440px] min-h-[43px]">
            <span className="text-[#5F5F59] text-[24px] text-center font-bold w-[440px] px-2 font-nunito">
              Вчитися легко, коли вчитися цікаво
            </span>
          </div>
        </div>
      </div>
      <div className="flex lg:flex-row flex-col lg:gap-x-[38px] gap-[20px]">
        <div className="flex flex-col gap-[20px]">
          <div className="lg:w-[436px] lg:h-[122px] bg-white rounded-[10px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.25)] p-[10px]">
            <div className="min-h-[27px] lg:mb-0 mb-[10px] rounded-[5px] bg-gradient-to-b from-[#FFD6BC] to-[#FFC6A0] shadow-[0px_2px_4px_0px_rgba(0,0,0,0,0.25)] flex justify-center items-center">
              <span className="text-[20px] text-[#5f5f59] font-black px-[10px] font-nunito">
                Яскраві і цікаві уроки - граючись!
              </span>
            </div>
            <div>
              <span className="text-[14px] text-[#5f5f59] font-medium font-montserrat leading-2">
                Дидактичні ігри, інтерактивні квести, дослідження, екскурсії,
                навчальні проєкти. У кожному навчальному предметі – розділ
                «Логіка»!
              </span>
            </div>
          </div>
          <div className="lg:w-[436px] lg:h-[122px] bg-white rounded-[10px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.25)] p-[10px]">
            <div className="min-h-[27px] lg:mb-0 mb-[10px] rounded-[5px] bg-gradient-to-b from-[#FFD6BC] to-[#FFC6A0] shadow-[0px_2px_4px_0px_rgba(0,0,0,0,0.25)] flex justify-center items-center">
              <span className="text-[20px] text-[#5f5f59] font-black px-[10px] font-nunito">
                Улюблені казкові герої завжди поруч!
              </span>
            </div>
            <div>
              <span className="text-[14px] text-[#5f5f59] font-medium font-montserrat leading-2">
                Ваша дитина буде вчитись, навчаючи, збираючи свого улюбленого
                супер-героя
              </span>
            </div>
          </div>
          <div className="lg:w-[436px] lg:h-[122px] bg-white rounded-[10px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.25)] p-[10px]">
            <div className="min-h-[27px] lg:mb-0 mb-[10px] rounded-[5px] bg-gradient-to-b from-[#FFD6BC] to-[#FFC6A0] shadow-[0px_2px_4px_0px_rgba(0,0,0,0,0.25)] flex justify-center items-center">
              <span className="text-[20px] text-[#5f5f59] font-black px-[10px] font-nunito">
                Діти навчаються не заради оцінок!
              </span>
            </div>
            <div>
              <span className="text-[14px] text-[#5f5f59] font-medium font-montserrat leading-2">
                Унікальна система оцінювання: селфі-аудити, самооцінювання! Ваша
                дитина самостійно визначає рівень своїх досягнень!
              </span>
            </div>
          </div>
          <div className="lg:w-[436px] lg:h-[141px] bg-white rounded-[10px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.25)] p-[10px]">
            <div className="min-h-[27px] lg:mb-0 mb-[10px] rounded-[5px] bg-gradient-to-b from-[#FFD6BC] to-[#FFC6A0] shadow-[0px_2px_4px_0px_rgba(0,0,0,0,0.25)] flex justify-center items-center">
              <span className="text-[20px] text-[#5f5f59] font-black px-[10px] font-nunito leading-[26px]">
                Враховуємо вікові і індивідуальні особливості Вашої дитини!
              </span>
            </div>
            <div>
              <span className="text-[14px] text-[#5f5f59] font-medium font-montserrat">
                Визначаємо на пробному уроці необхідність вікового та
                індивідуального підходу до дитини та запис до відповідної
                категорії
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="bg-white rounded-[10px] p-[10px] lg:w-[436px] min-h-[168px]">
            <div className="bg-gradient-to-b lg:mb-0 mb-[10px] from-[#FFD6BC] to-[#FFC6A0] rounded-[5px] shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)] px-[10px]">
              <span className="text-[20px] text-[#5f5f59] font-black font-nunito leading-[26px]">
                Батьки не спонсори, а активні учасники шкільного життя дитини!
              </span>
            </div>
            <div className="lg:w-[345px]">
              <span className="text-[14px] font-medium text-[#5f5f59] font-montserrat">
                Ставте свої запитання, діліться своїми відгуками про уроки та
                побажаннями.
              </span>
            </div>
          </div>
          <div className="mt-[95px] lg:translate-x-[70px]">
            <img src={FamilySova} alt="FamilySova" className="lg:h-[325px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parents;
