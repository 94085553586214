import axios from "axios";
import { useEffect, useState } from "react";
import Verchenko from "../../../images/team/Verchenko.svg";
import SovaMoodrik from "../../../images/SovaMoodrik.webp";
import Rainbow from "../../../images/Rainbow.webp";
import { ENDPOINTS } from "../../../api/apiEndpoints";
interface TeamMember {
  id: number;
  title: string;
  position: string;
  name: string;
  description: string;
  photo_url: string;
}

const Teams = () => {
  const [teamData, setTeamData] = useState<TeamMember[]>([]);

  useEffect(() => {
    axios
      .get(ENDPOINTS.getTeam)
      .then((response) => {
        setTeamData(response.data.team_members);
        console.log(response.data.team_members);
      })
      .catch((error) => {
        console.error("Помилка отримання даних:", error);
      });
  }, []);

  return (
    <div className="flex justify-center mt-[105px]">
      <div className="max-w-[1390px] w-full px-[50px]">
        <div className="flex lg:flex-row flex-col-reverse justify-center items-center lg:gap-[320px]">
          <div className="lg:w-[394px] w-[224px] lg:h-[72px] h-[32px] lg:mt-0 mt-10 bg-[#E8CBA0] rounded-[6px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)] flex justify-center items-center">
            <span className="text-[#ffffff] lg:text-[40px] text-[20px] font-black font-nunito">
              НАША КОМАНДА
            </span>
          </div>
          <div>
            <img
              src={SovaMoodrik}
              alt="SovaMoodrik"
              className="lg:w-[300px] w-[175px] lg:h-[150px] h-[87px]"
            />
          </div>
        </div>
        <div className="flex items-end gap-[170px] mt-[83px]">
          <div className="flex lg:flex-row flex-col gap-[31px] lg:w-[593px] min-h-[388px] rounded-[10px] px-[26px] py-[32px] bg-gradient-to-b from-[#E8CBA0] to-[#E8CBA0] shadow-[1px_4px_15px_0px_rgba(0,0,0,0.10)]">
            <div className="flex flex-col justify-center items-center">
              <div>
                <img
                  src={Verchenko}
                  alt="Верченко Віталія"
                  className="lg:w-[240px] w-[180px] lg:h-[264px] h-[197px] object-cover object-[20%_50%]"
                />
              </div>
              <div className="bg-gradient-to-b from-[#FFF8EC] to-[#FFFFFF] w-[248px] min-h-[36px] flex justify-center items-center rounded-md shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)] mt-[24px]">
                <span className="text-[20px] text-[#484C4C] font-bold font-montserrat">
                  Верченко Віталія
                </span>
              </div>
            </div>
            <div>
              <div>
                <span className="text-[#FFF] text-[16px] font-bold font-montserrat">
                  Автор проєкту “Маленький Мудрик”, вчитель початкових класів
                </span>
              </div>
              <div className="bg-[#FFDC97] h-[1px] max-w-[268px] w-full my-[12px]" />
              <div>
                <span className="text-[#5F5F59] font-medium text-[14px] font-montserrat">
                  Досвід викладання 8 років з дітьми від 4 до 11 років в онлайн
                  та офлайн форматі. <br /> За освітою вчитель початкових
                  класів, сімейний педагог. <br /> Постійно займається
                  самоосвітою, підвищення своєї кваліфікації. Активно працює з
                  дітьми батьками та педагогами. Обожнює навчатись та навчати
                  найменших!
                </span>
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <img src={Rainbow} alt="Rainbow" className="h-[183px] w-[337px]" />
          </div>
        </div>
        <div className="mb-[105px] mt-[93px] grid lg:grid-cols-2 grid-col-1 gap-y-[41px]">
          {teamData.map((el, index) => (
            <div
              key={index}
              className="flex lg:flex-row flex-col gap-[31px] lg:w-[593px] w-[330px] min-h-[388px] rounded-[10px] px-[26px] py-[32px] lg:bg-white bg-gradient-to-b to-[#F1F1F1] from-[#FFFFFF] shadow-[1px_4px_15px_0px_rgba(0,0,0,0.10)]"
            >
              <div className="flex flex-col justify-center items-center">
                <div>
                  <img
                    className="w-[240px] h-[264px] object-cover object-[20%_50%]"
                    src={`https://mmudryk.com.ua/${el.photo_url}`}
                    alt={`Teacher ${index + 1}`}
                  />
                </div>
                <div className="bg-gradient-to-b from-[#FEE6BA] to-[#FFDB95] w-[248px] min-h-[36px] flex justify-center items-center rounded-md shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)] mt-[24px] py-[6px]">
                  <span className="text-[20px] font-bold text-[#484C4C] text-center font-montserrat">
                    {el.name}
                  </span>
                </div>
              </div>
              <div>
                <div className="flex justify-center lg:justify-start">
                  <span className="text-[#4A4A4A] text-[16px] font-bold font-montserrat">
                    {el.title}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <span className="text-[#4A4A4A] text-[16px] font-bold font-montserrat">
                    {el.position}
                  </span>
                </div>
                <div className="bg-[#FFDC97] h-[1px] max-w-[268px] w-full my-[12px]" />
                <div>
                  <span className="text-[#5F5F59] font-medium text-[14px] font-montserrat">
                    {el.description}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Teams;
