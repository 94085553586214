const BASE_URL = "https://mmudryk.com.ua/api/v1";

export const ENDPOINTS = {
  getTeam: `${BASE_URL}/get_team/`, // +
  getFAQ: `${BASE_URL}/get_faq/`, // +
  getCourses: `${BASE_URL}/get_courses/`, // +
  getDetailCard: `${BASE_URL}/detail_cards/`, // +
  getCoursesTitle: `${BASE_URL}/get_courses_title/`, // +
  postFeedback: `${BASE_URL}/post_feedback/`, // +
  getReviews: `${BASE_URL}/get_reviews/`, // +
  getSchedule: `${BASE_URL}/get_schedule/`, // +
};
