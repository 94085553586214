import Figura1 from "../../images/1.webp";
import Figura2 from "../../images/2.webp";
import Figura3 from "../../images/3.webp";
import Figura4 from "../../images/4.webp";
import ICardMediaCenter from "../../interfaces/cardMediaCenter.interface";
import CardMissionCenter from "./CardMissionCenter";

const cards: ICardMediaCenter[] = [
  {
    id: 0,
    title: "01",
    description:
      "Дитина залишається постійно у безпеці, під батьківським наглядом та здобуває якісну освіту",
  },
  {
    id: 1,
    title: "02",
    description: `Дитина з певних причин не може відвідувати офлайн-заняття`,
  },
  {
    id: 2,
    title: "03",
    description:
      "Перебуваєте за кордоном, але прагнете навчати дитину за українською програмою",
  },
  {
    id: 3,
    title: "04",
    description:
      "Навчаєте дитину самі й бажаєте створити їй якнайбільше можливостей для пізнання, розвитку і спілкування",
  },
  {
    id: 4,
    title: "05",
    description: "Навчання, комфортне і для дитини, і для батьків",
    img: Figura1,
  },
  {
    id: 5,
    title: "06",
    description: "Якісні педагогічні послуги в будь-якому куточку світу",
    img: Figura3,
  },
  {
    id: 6,
    title: "07",
    description: "Заняття з елементами інтерактиву, відео й аудіо",
    img: Figura2,
  },
  {
    id: 7,
    title: "08",
    description: "Працюємо індивідуально та колективно",
    img: Figura4,
  },
];

const MissionCenter = () => {
  return (
    <div className="mb-[70px]">
      <div className="flex flex-col items-center mt-[50px]">
        <h2 className="lg:text-[40px] text-[24px] text-[#5F5F59] font-black font-nunito">
          МІСІЯ ЦЕНТРУ
        </h2>
        <span className="lg:text-[16px] text-[13px] text-[#5F5F59] font-semibold lg:w-1/2 text-center mt-[7px] font-montserrat">
          сформувати онлайн-середовище, у якому дитина зможе розвиватись в
          своєму темпі та проявляти свою унікальність. Допомогти кожній дитині
          розвинути необхідні особисті якості, навички, таланти, які в
          майбутньому зроблять її успішним дорослим
        </span>
      </div>
      <div className="bg-[#E8CBA0] m-[0_auto] lg:mt-[57px] mt-[21px] lg:mb-[37px] mb-[54px] w-[207px] h-[33px] rounded-md flex justify-center items-center">
        <span className="text-[16px] text-[#ffffff] font-semibold font-montserrat">
          Чому обирають нас?
        </span>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="grid lg:grid-cols-4 grid-cols-2  gap-x-[32px] gap-y-[14px]">
          {cards.map((el) => (
            <CardMissionCenter cardData={el} key={el.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MissionCenter;
