import { BrowserRouter, Route, Routes } from "react-router-dom";
import GeneralLayout from "./components/layout/GeneralLayout";
import MainPages from "./components/pages/main/mainpages";
import Teams from "./components/pages/team/teams";
import Schedule from "./components/pages/schedule/schedule";
import Faq from "./components/pages/faq/faq";
import Lessons from "./components/pages/lesson/lesson";
import LessonDetail from "./components/pages/lesson/lessonDetail";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <GeneralLayout>
              <MainPages />
            </GeneralLayout>
          }
        />
        <Route
          path="/team"
          element={
            <GeneralLayout>
              <Teams />
            </GeneralLayout>
          }
        />
        <Route
          path="/lessons"
          element={
            <GeneralLayout>
              <Lessons />
            </GeneralLayout>
          }
        />
        <Route
          path="lessons/:id"
          element={
            <GeneralLayout>
              <LessonDetail />
            </GeneralLayout>
          }
        />
        <Route
          path="/schedule"
          element={
            <GeneralLayout>
              <Schedule />
            </GeneralLayout>
          }
        />
        <Route
          path="/faq"
          element={
            <GeneralLayout>
              <Faq />
            </GeneralLayout>
          }
        />
        <Route
          path="/contacts"
          element={
            <GeneralLayout>
              <div></div>
            </GeneralLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
