import { Carousel } from "antd";
import ICardAdvantages from "../../interfaces/cardAdvantages.interface";

interface IPropsCardAdvantages {
  cardData: ICardAdvantages;
}

const CardAdvantages = ({ cardData }: IPropsCardAdvantages) => {
  return (
    <div>
      <div className="flex bg-white lg:w-[441px] shadow-[0px_3px_15px_0px_rgba(0,0,0,0.25)] rounded-[10px] h-fit py-4 px-[14px] gap-x-5 justify-center ">
        <div>
          <div className="bg-[#FDE790] w-[303px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.25)] rounded-md pt-[7px] pb-[9px] pl-[7px] flex items-center mb-5">
            <span className="text-[#5F5F59] text-[14px] font-bold font-montserrat">
              {cardData.title}
            </span>
          </div>
          <div>
            <p className="text-[#5F5F59] w-[303px] text-[14px] font-montserrat font-medium leading-4">
              {cardData.description}
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <img
            src={cardData.img}
            alt={`Figura ${cardData.id + 1}`}
            className="h-[80px]"
          />
        </div>
      </div>
    </div>
  );
};

export default CardAdvantages;
