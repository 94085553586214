import FeedBack from "../feedback/feedback";
import Calendar from "../../images/Calendar.webp";
import Phone from "../../images/Phone.webp";
import Email from "../../images/Email.svg";
import Telegram from "../../images/Telegram.webp";
import Viber from "../../images/Viber.webp";
import SubFooter from "./subfooter";
import Whatsapp from "../../images/whatsapp.svg";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  const isActive = (path: string) => location.pathname === path;
  return (
    <div>
      <div
        className={`flex justify-center bg-gradient-to-b from-[#fde6ba] to-[#ffda94] ${
          isActive("/team") && "hidden"
        }`}
      >
        <div className="max-w-[1390px] flex flex-col justify-center w-full h-auto px-[50px]">
          <div className="py-[43px]">
            <div className="hidden lg:flex justify-center mb-[20px]">
              <span className="text-white text-[16px] font-semibold bg-[#E8CBA0] max-w-[200px] w-full h-[33px] flex justify-center items-center rounded-md font-montserrat">
                Наші контакти
              </span>
            </div>
            <div className="hidden lg:flex justify-center">
              <h1 className="text-[#5F5F59] text-[40px] text-center font-black mb-[33px] font-nunito">
                ЗВ’ЯЗАТИСЯ З НАМИ
              </h1>
            </div>
            <div className="flex lg:flex-row flex-col gap-[60px] justify-center">
              <div className="flex flex-col justify-end">
                <div className="mb-[28px]">
                  <h2 className="text-[#5F5F59] lg:text-[32px] text-[20px] text-center lg:text-left font-black font-nunito">
                    Записатися на пробний урок:
                  </h2>
                </div>
                <div className="">
                  <FeedBack />
                </div>
              </div>
              <div className="bg-white lg:w-[442px] lg:h-[455px] h-fit rounded-lg shadow-[0px_2px_10px_0px_rgba(0,0,0,0.25)] px-[31px] py-[34px]">
                <div className="mb-[20px] flex justify-center">
                  <h2 className="lg:text-[32px] text-[20px] text-center font-black text-[#5F5F59] font-nunito">
                    НАШІ КОНТАКТИ
                  </h2>
                </div>
                <div className="flex flex-col gap-[46px]">
                  <div className="flex gap-7">
                    <div className="lg:w-[70px] w-[55px] lg:h-[70px] h-[55px] bg-white flex justify-center items-center shadow-[0px_1px_9px_0px_rgba(0,0,0,0.10)] rounded-[3px]">
                      <div className="lg:w-[59px] lg:h-[59px] w-[44px] h-[44px] rounded-[3px] bg-[#FFD6BC] flex justify-center items-center">
                        <img
                          src={Calendar}
                          alt="Calendar"
                          className="lg:h-[32px] h-[28px]"
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <h2 className="text-[16px] font-bold text-[#5F5F59] font-nunito">
                          Графік роботи:
                        </h2>
                      </div>
                      <div className="lg:text-[16px] text-[13px] text-[#5F5F59] font-nunito">
                        <span>понеділок-пʼятниця: 10:00-20:00</span>
                        <br />
                        <span>субота-неділя: 11:00-18:00</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-7">
                    <div className="lg:w-[70px] w-[55px] lg:h-[70px] h-[55px] bg-white flex justify-center items-center shadow-[0px_1px_9px_0px_rgba(0,0,0,0.10)] rounded-[3px]">
                      <div className="lg:w-[59px] lg:h-[59px] w-[44px] h-[44px] rounded-[3px] bg-[#FFD6BC] flex justify-center items-center">
                        <img
                          src={Phone}
                          alt="Phone"
                          className="lg:h-[25px] h-[20px]"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="text-[16px] font-bold text-[#5F5F59] font-nunito">
                        <h2>Телефон:</h2>
                      </div>
                      <div>
                        <span className="lg:text-[16px] text-[13px] text-[#5F5F59] font-nunito">
                          +38 (093) 322 38 63
                        </span>
                        <br />
                        <div className="flex gap-2">
                          <a href="https://web.telegram.org/k/" target="blank">
                            <img
                              src={Telegram}
                              alt="Telegram"
                              className="h-7"
                            />
                          </a>
                          <a href="https://www.viber.com/ru/" target="blank">
                            <img src={Viber} alt="Viber" className="h-7" />
                          </a>
                          <a href="https://wa.me/933223863" target="blank">
                            <img src={Whatsapp} alt="Whatsapp" className="h-7" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-7">
                    <div className="lg:w-[70px] w-[55px] lg:h-[70px] h-[55px] bg-white flex justify-center items-center shadow-[0px_1px_9px_0px_rgba(0,0,0,0.10)] rounded-[3px]">
                      <div className="lg:w-[59px] lg:h-[59px] w-[44px] h-[44px] rounded-[3px] bg-[#FFD6BC] flex justify-center items-center">
                        <img
                          src={Email}
                          alt="Email"
                          className="lg:h-[25px] h-[20px]"
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <h2 className="text-[16px] font-bold text-[#5F5F59] font-nunito">
                          Електронна скринька:
                        </h2>
                      </div>
                      <div>
                        <a
                          href="mailto:mudrikmalenkij@gmail.com"
                          className="lg:text-[16px] text-[13px] text-[#5F5F59] font-nunito"
                        >
                          mudrikmalenkij@gmail.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center bg-gradient-to-b from-[#FDE6BA] to-[#FFDA94]">
        <SubFooter />
      </div>
    </div>
  );
};

export default Footer;
