import axios from "axios";
import { Button, Form, Input, Select, notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { ENDPOINTS } from "../../api/apiEndpoints";
import ReCAPTCHA from "react-google-recaptcha";
const { TextArea } = Input;
interface SelectLessonType {
  id: number;
  title: string;
}

type NotificationType = "success" | "info" | "warning" | "error";

const FeedBack = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    console.log(values);
    axios
      .post(ENDPOINTS.postFeedback, values)
      .then((response) => {
        setLessonSelect(response.data.courses_title);
        console.log("Успішно відправлено:", response);
        openNotificationWithIcon(
          "success",
          "Успішно !",
          "Запит успішно надіслано."
        );
        form.resetFields();
      })
      .catch((error) => {
        console.error("Помилка відправки:", error);
        openNotificationWithIcon(
          "error",
          "Помилка !",
          "Не вдалося надіслати запит спробуйте пізніше."
        );
        form.resetFields();
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const [lessonSelect, setLessonSelect] = useState<SelectLessonType[]>([]);

  const [api, contextHolder] = notification.useNotification();

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .get(ENDPOINTS.getCoursesTitle)
      .then((response) => {
        const coursesTitles = response.data.courses_title;
        setLessonSelect(
          coursesTitles.map((title: string, index: number) => ({
            id: index,
            title: title,
          }))
        );
      })
      .catch((error) => {
        console.error("Помилка при завантаженні заголовків курсів:", error);
      });
    return () => {
      isMounted.current = false;
    };
  }, []);

  const openNotificationWithIcon = (
    type: NotificationType,
    message: string,
    description: string
  ) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  // Valid number
  const validatePhoneNumber = (_: any, value: string) => {
    const regex = /^(\+?\d{9,12}|\d{9,12})$/;
    if (!regex.test(value)) {
      return Promise.reject("");
    }
    return Promise.resolve();
  };
  //

  // Valid Email
  const validateEmail = (_: any, value: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!regex.test(value)) {
      return Promise.reject("");
    }
    return Promise.resolve();
  };
  //

  return (
    <div className="max-w-[675px]">
      {contextHolder}
      <Form onFinish={onFinish} onFinishFailed={onFinishFailed} form={form}>
        <div className="flex flex-col lg:flex-row lg:gap-7">
          <Form.Item
            name="name"
            className="w-full font-montserrat"
            rules={[
              {
                required: true,
                message: "Будь ласка, введіть ім'я!",
              },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Ім'я"
              className="shadow-[0px_2px_10px_0px_rgba(0,0,0,0.25)] !h-[42px] !rounded-lg text-[16px] !text-[#878787] font-montserrat"
            />
          </Form.Item>
          <Form.Item
            name="surname"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Будь ласка, введіть прізвище!",
              },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Прізвище"
              className="shadow-[0px_2px_10px_0px_rgba(0,0,0,0.25)] !h-[42px] !rounded-lg text-[16px] !text-[#878787] font-montserrat"
            />
          </Form.Item>
        </div>
        <div className="flex flex-col lg:flex-row lg:gap-7 lg:mb-0 mb-8">
          <Form.Item
            name="email"
            hasFeedback
            className="w-full"
            rules={[
              {
                required: true,
                message: "Будь ласка, введіть поштову скриньку!",
              },
              {
                validator: validateEmail,
              },
            ]}
          >
            <Input
              placeholder="Електронна скринька"
              className="shadow-[0px_2px_10px_0px_rgba(0,0,0,0.25)] !h-[42px] !rounded-lg text-[16px] !text-[#878787] font-montserrat"
            />
          </Form.Item>
          <Form.Item
            name="phone"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Будь ласка, введіть номер телефону!",
              },
              {
                validator: validatePhoneNumber,
              },
            ]}
            hasFeedback
          >
            <Input
              placeholder="Телефон"
              className="shadow-[0px_2px_10px_0px_rgba(0,0,0,0.25)] !h-[42px] !rounded-lg text-[16px] !text-[#878787] font-montserrat"
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="lesson"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Будь ласка, введіть урок!",
              },
            ]}
            hasFeedback
          >
            <Select
              placeholder="Урок"
              className="shadow-[0px_2px_10px_0px_rgba(0,0,0,0.25)] !h-[42px] !rounded-lg text-[16px] !text-[#878787] font-montserrat"
            >
              {lessonSelect &&
                lessonSelect.map((lesson: SelectLessonType) => (
                  <Select.Option key={lesson.id} value={lesson.title}>
                    {lesson.title}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item
            name="message"
            className="w-full !m-0"
            rules={[
              {
                required: true,
                message: "Будь ласка, введіть повідомлення!",
              },
            ]}
            hasFeedback
          >
            <TextArea
              className="!h-[179px] lg:!w-[674px] shadow-[0px_2px_10px_0px_rgba(0,0,0,0.25)] !rounded-lg text-[16px] !text-[#878787] font-montserrat"
              placeholder="Повідомлення"
            />
          </Form.Item>
        </div>
        <div className="flex justify-center mt-[30px]">
          <Form.Item className="">
            <ReCAPTCHA
              size="invisible"
              sitekey="6LdvQsspAAAAAFtGuDYLC_V---THuzwmO4FE0vYL"
            />
            <Button
              htmlType="submit"
              className="bg-[#E8CBA0] text-[16px] font-bold flex items-center justify-center text-[#FFF] border-none w-[143px] h-[42px] shadow-[0px_2px_10px_0px_rgba(0,0,0,0.25)] rounded-lg hover:!bg-[#E8CBA099] hover:!text-[#FFF] font-montserrat"
            >
              Надіслати
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default FeedBack;
