import Mone from "./../../images/M-1.webp";
import Mtwo from "./../../images/M-2.webp";
import Sova from "./../../images/Sova.webp";

const Intro = () => {
  function scrollToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  const handleClick = (): void => {
    scrollToBottom();
  };
  return (
    <div className="flex flex-col lg:flex-row lg:mb-[0px] mb-[50px]">
      <div className="flex flex-col items-center lg:translate-x-[110px] lg:translate-y-[45px]">
        <div className="flex items-center z-0">
          <img
            src={Mone}
            alt="M-1"
            className="lg:w-[157px] lg:h-[157px] w-[80px] h-[80px]"
          />
          <span
            id="text"
            className="lg:text-[128px] text-[56px] font-bold text-white font-nunito"
          >
            аленький
          </span>
        </div>
        <div className="flex items-center lg:translate-x-[163px] translate-x-[40px] lg:translate-y-[-36px] translate-y-[-8px]">
          <img
            src={Mtwo}
            alt="M-2"
            className="lg:w-[157px] lg:h-[157px] w-[80px] h-[80px]"
          />
          <span
            id="text"
            className="lg:text-[128px] text-[56px] font-bold text-white font-nunito"
          >
            удрик
          </span>
        </div>
        <div className="lg:bg-[#FFFFFF] bg-[rgba(255,255,255,0.6)] max-w-[572px] w-full flex justify-center items-center rounded-[5px] z-10">
          <span className="lg:text-[36px] text-[18px] font-medium lg:py-[5px] py-[2px] lg:px-[16px] text-[#5F5F59] font-nunito">
            Онлайн центр розвитку дитини
          </span>
        </div>
      </div>
      <div className="flex justify-center lg:translate-y-[-135px] translate-y-[-65px] lg:translate-x-[-10px]">
        <img src={Sova} alt="Sova" className="lg:h-[917px] h-[480px]" />
      </div>
      <div className="flex lg:absolute top-[75%] left-[33%] justify-center max-w-[360px] w-full h-[54px] text-[#575A5A] font-montserrat font-semibold text-[18px] bg-gradient-to-b to-[#F1EFE7] from-[#ffffff] px-[14px] py-[16px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)] active:scale-90 transform transition-all duration-300 rounded-[15px]">
        <button onClick={handleClick}>Записатися на пробний урок</button>
      </div>
    </div>
  );
};

export default Intro;
