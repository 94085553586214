import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import Logo from "../../images/Logo.webp";
import Instagram from "../../images/Instagram.webp";
import FaceBoock from "../../images/FaceBoock.webp";
import LogoMenu from "../../images/Logo_burger.svg";
import "./style.css";

const SubFooter = () => {
  const location = useLocation();
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleToggleMenu = () => {
    setIsActiveMobileMenu((prev) => !prev);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsActiveMobileMenu(false);
    };

    const unlisten = () => {
      handleRouteChange();
    };

    return () => {
      unlisten();
    };
  }, [location]);

  const isActive = (path: string) => location.pathname === path;
  return (
    <div className="max-w-[1390px] flex flex-col justify-center w-full h-[98px] px-[50px] py-[36px]">
      <ul className="lg:flex hidden justify-between items-center text-[#484C4C] text-[16px] font-montserrat">
        <li>
          <NavLink to={"/"} onClick={scrollToTop}>
            <img src={Logo} alt="Logo" className="w-[79px] h-[79px]" />
          </NavLink>
        </li>
        <li
          className={`relative ${
            isActive("/") && "text-[#C8A664] font-semibold"
          }`}
        >
          <NavLink to={"/"} onClick={scrollToTop}>
            Головна
          </NavLink>
        </li>

        <li
          className={`z-10 ${
            isActive("/team") && "text-[#C8A664] font-semibold "
          }`}
        >
          <NavLink to={"/team"} onClick={scrollToTop}>
            Наша команда
          </NavLink>
        </li>
        <li
          className={`z-10 ${
            isActive("/lessons") && "text-[#C8A664] font-semibold "
          }`}
        >
          <NavLink to={"/lessons"} onClick={scrollToTop}>
            Наші уроки
          </NavLink>
        </li>
        <li
          className={`z-10 ${
            isActive("/schedule") && "text-[#C8A664] font-semibold "
          }`}
        >
          <NavLink to={"/schedule"} onClick={scrollToTop}>
            Розклад уроків
          </NavLink>
        </li>
        <li
          className={`z-10 ${
            isActive("/faq") && "text-[#C8A664] font-semibold "
          }`}
        >
          <NavLink to={"/faq"} onClick={scrollToTop}>
            FaQ
          </NavLink>
        </li>
        <li
          className={`z-10 ${
            isActive("/contacts") && "text-[#C8A664] font-semibold "
          }`}
        >
          <NavLink to={"/contacts"} onClick={scrollToTop}>
            Контакти
          </NavLink>
        </li>
        <li>
          <div className="w-[158px] h-[72px] bg-gradient-to-b from-[#EDEDED] to-[#FFFFFF] rounded-md">
            <div className="flex justify-center">
              <span className="text-[16px] text-[#AA8D56]">
                Соціальні мережі
              </span>
            </div>
            <div className="flex justify-center gap-7 mt-[5px]">
              <a
                href="https://www.instagram.com/malenkyimydruk?igsh=OHY2ajJwdTh4aHh2"
                target="blank"
              >
                <img
                  src={Instagram}
                  alt="Instagram"
                  className="w-[31px] h-[31px]"
                />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61557826356135"
                target="blank"
              >
                <img
                  src={FaceBoock}
                  alt="FaceBoock"
                  className="w-[31px] h-[31px]"
                />
              </a>
            </div>
          </div>
        </li>
      </ul>
      <div className="flex justify-between items-center">
        <div className="overflow-hidden">
          <div className="flex justify-between lg:hidden">
            <div className="flex">
              <div className="flex items-center w-[30px] lg:w-0 z-40">
                <button
                  onClick={handleToggleMenu}
                  className="lg:hidden flex absolute"
                >
                  <input
                    type="checkbox"
                    id="menu_checkbox"
                    className="absolute z-10 w-[30px] h-[30px]"
                    checked={isActiveMobileMenu}
                  />
                  <label htmlFor="menu_checkbox">
                    <div></div>
                    <div></div>
                    <div></div>
                  </label>
                </button>
              </div>
            </div>
          </div>
          <div
            className={`transition-all duration-400 w-full z-30 bg-white opacity-90 left-[8%] rounded-[15px] absolute max-w-[360px] ${
              isActiveMobileMenu
                ? "translate-y-[-90%]"
                : "translate-y-[100%] hidden"
            }`}
          >
            <div className="flex justify-end pr-10 pt-10">
              <img src={LogoMenu} alt="Logo_menu" />
            </div>
            <ul className="flex flex-col items-center gap-4 px-10 pb-10 text-[#484C4D] font-semibold">
              <li className={`relative ${isActive("/") && "text-[#C8A664]"}`}>
                <NavLink to={"/"} onClick={() => scrollToTop()}>
                  Головна
                </NavLink>
              </li>

              <li className={`z-10 ${isActive("/team") && "text-[#C8A664]"}`}>
                <NavLink to={"/team"} onClick={() => scrollToTop()}>
                  Наша команда
                </NavLink>
              </li>
              <li
                className={`z-10 ${isActive("/lessons") && "text-[#C8A664]"}`}
              >
                <NavLink to={"/lessons"} onClick={() => scrollToTop()}>
                  Наші уроки
                </NavLink>
              </li>
              <li
                className={`z-10 ${isActive("/schedule") && "text-[#C8A664]"}`}
              >
                <NavLink to={"/schedule"} onClick={() => scrollToTop()}>
                  Розклад уроків
                </NavLink>
              </li>
              <li className={`z-10 ${isActive("/faq") && "text-[#C8A664]"}`}>
                <NavLink to={"/faq"} onClick={() => scrollToTop()}>
                  FAQ
                </NavLink>
              </li>
              <li
                className={`z-10 ${isActive("/contacts") && "text-[#C8A664]"}`}
              >
                <NavLink to={"/contacts"} onClick={() => scrollToTop()}>
                  Контакти
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-col lg:hidden w-[123px] h-[56px] bg-gradient-to-b from-[#EDEDED] to-[#FFFFFF] rounded-md px-[2px] py-[6px]">
          <div className="flex justify-center">
            <span className="text-[12px] text-[#AA8D56]">Соціальні мережі</span>
          </div>
          <div className="flex justify-center gap-7 mt-[2px]">
            <a
              href="https://www.instagram.com/mydruk__?igsh=OHY2ajJwdTh4aHh2"
              target="blank"
            >
              <img
                src={Instagram}
                alt="Instagram"
                className="w-[24px] h-[24px]"
              />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61555677750471"
              target="blank"
            >
              <img
                src={FaceBoock}
                alt="FaceBoock"
                className="w-[24px] h-[24px]"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
