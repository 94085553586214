import ICardMediaCenter from "../../interfaces/cardMediaCenter.interface";

interface IPropsCardMissionCenter {
  cardData: ICardMediaCenter;
}

const CardMissionCenter = ({ cardData }: IPropsCardMissionCenter) => {
  return (
    <div className="bg-white rounded-[10px] lg:max-w-[238px] max-w-[166px] w-full lg:h-[214px] h-[155px] shadow-[0px_4px_15px_0px_rgba(0,0,0,0.1)] flex flex-col items-center p-[10px]">
      <div className="flex items-start lg:h-fit h-1/3 mb-1">
        <span className="text-[40px] font-black text-[#5F5F59] opacity-[0.22] font-montserrat">
          {cardData.title}
        </span>
      </div>
      <div>
        <p className="lg:text-[15px] text-[11px] font-medium text-[#5F5F59] text-center font-montserrat lg:leading-[18px] leading-[14px]">
          {cardData.description}
        </p>
      </div>
      {cardData.img && (
        <img
          src={cardData.img}
          alt={`Figura${cardData.id + 1}`}
          className="mt-1 w-[144px] h-[78px] lg:block hidden"
        />
      )}
    </div>
  );
};

export default CardMissionCenter;
