import BG from "../../../images/BG.webp";
import Intro from "../../intro/intro";
import MissionCenter from "../../missionCenter/missionCenter";
import Advantages from "../../advantages/advantages";
import Discount from "../../discount/discount";
import Parents from "../../parents/parents";
import Rewievs from "../../rewievs/reviews";

const MainPages = () => {
  return (
    <div>
      <div className="flex justify-center bg-gradient-to-b from-[#FDE6BA] to-[#FFDA94]">
        <section className="max-w-[1390px] w-full px-[50px]">
          <Intro />
        </section>
      </div>
      <div className="flex justify-center">
        <section className="max-w-[1390px] w-full px-[50px]">
          <MissionCenter />
          <img
            className="translate-y-[-280px] z-[-1] relative"
            src={BG}
            alt="Backgraund"
          />
        </section>
      </div>
      <div className="flex justify-center bg-gradient-to-b from-[#FDE790] to-[#FCE071]">
        <section className="max-w-[1390px] w-full px-[50px]">
          <Advantages />
        </section>
      </div>
      <div className="flex justify-center">
        <section className="max-w-[1390px] w-full px-[50px]">
          <Discount />
        </section>
      </div>
      <div className="flex justify-center bg-gradient-to-b from-[#FFD6BC] to-[#FFC59F]">
        <section className="max-w-[1390px] w-full px-[50px]">
          <Parents />
        </section>
      </div>
      <div className="flex justify-center">
        <section className="max-w-[1390px] w-full px-[50px]">
          <Rewievs />
        </section>
      </div>
    </div>
  );
};

export default MainPages;
