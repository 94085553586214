import React, { ReactNode, useEffect, useState } from "react";
import Footer from "./../footer/footer";
import Header from "./../header/header";
import { BackToTopButton } from "../button/backToTopButton";
import { useLocation } from "react-router-dom";

interface IPropsGeneralLayout {
  children: ReactNode;
}

const GeneralLayout = ({ children }: IPropsGeneralLayout) => {
  const [isFadeAvtive, setIsFadeActive] = useState(false);

  const router = useLocation();

  return (
    <>
      <header>
        <Header setIsFadeActive={setIsFadeActive} />
      </header>
      <main
        className={` ${
          isFadeAvtive ? "opacity-0" : "opacity-100"
        } transition-opacity duration-500 `}
      >
        {children}
        <BackToTopButton />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default GeneralLayout;
