import { useEffect, useState } from "react";
import { Carousel } from "antd";
import Avatar from "../../images/Avatar.webp";
import axios from "axios";
import { ENDPOINTS } from "../../api/apiEndpoints";

interface Review {
  id: number;
  name: string;
  feedback_text: string;
}

const Reviews = () => {
  const [reviewsData, setReviewsData] = useState<Review[]>([]);

  useEffect(() => {
    axios
      .get(ENDPOINTS.getReviews)
      .then((response) => {
        setReviewsData(response.data.reviews);
        console.log(response.data.reviews);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const groupedReviews = [];
  if (reviewsData && reviewsData.length > 0) {
    for (let i = 0; i < reviewsData.length; i += 3) {
      groupedReviews.push(reviewsData.slice(i, i + 3));
    }
  } else {
    console.warn("reviewsData порожній або має невірний формат.");
  }

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="mt-[43px] mb-[160px]">
      <div className="flex justify-center mb-[114px]">
        <div className="flex justify-center items-center bg-[#E8CBA0] rounded-md w-[207px] h-[33px]">
          <span className="text-[16px] font-semibold text-white font-montserrat">
            Наші відгуки
          </span>
        </div>
      </div>
      {windowWidth > 500 ? (
        <Carousel autoplay fade dots autoplaySpeed={5000}>
          {groupedReviews.map((group, index) => (
            <div
              key={index}
              className="!flex justify-center gap-8 items-center h-[510px]"
            >
              {group.map((review, index) => (
                <div
                  className="bg-white rounded-[8px] w-[404px] h-[455px] shadow-[0px_5px_8px_0px_rgba(0,0,0,0.15)] flex flex-col items-center px-[47px] "
                  key={index}
                >
                  <div className="w-[129px] h-[129px] bg-white border-[12px] border-[#FFC6a0] rounded-full mb-[17px] flex justify-center items-center mt-[15px]">
                    <img
                      className="rounded-full h-[105px]"
                      src={Avatar}
                      alt={`Avatar ${review.id + 1}`}
                    />
                  </div>

                  <div className="mt-[17px] mb-[23px] flex justify-center">
                    <h2 className="text-[32px] font-black text-[#5f5f59] text-center leading-9 font-nunito">
                      {review.name}
                    </h2>
                  </div>

                  <div>
                    <span className="text-center text-[#5f5f59] text-[16px] flex justify-center font-nunito">
                      {review.feedback_text}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Carousel>
      ) : (
        <Carousel autoplay dots autoplaySpeed={5000}>
          {reviewsData.map((review, index) => (
            <div
              key={index}
              className="!flex justify-center gap-8 items-center h-[510px]"
            >
              <div
                className="bg-white rounded-[8px] w-[282px] h-[308px] shadow-[0px_5px_8px_0px_rgba(0,0,0,0.15)] flex flex-col items-center px-[47px] "
                key={index}
              >
                <div className="w-[87px] h-[87px] bg-white border-[12px] border-[#FFC6a0] rounded-full mb-[17px] flex justify-center items-center mt-[15px]">
                  <img
                    className="rounded-full h-[66px]"
                    src={Avatar}
                    alt={`Avatar ${review.id + 1}`}
                  />
                </div>

                <div className="mt-[17px] mb-[23px] flex justify-center">
                  <h2 className="text-[20px] font-black text-[#5f5f59] text-center leading-5 font-nunito">
                    {review.name}
                  </h2>
                </div>
                <div>
                  <span className="text-center text-[#5f5f59] text-[16px] flex justify-center font-nunito leading-4">
                    {review.feedback_text}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default Reviews;
