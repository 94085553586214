import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SovaLogoLesson from "../../../images/Sova_logo_lesson.webp";
import LeftLesson from "../../../images/left_lesson.webp";
import axios from "axios";
import { ENDPOINTS } from "../../../api/apiEndpoints";

interface CardDetail {
  age: string;
  duration: number;
  id: number;
  max_members: number;
  photo: string;
  price: any;
  bold_text: string;
  text: string;
  short_description: any;
  text_blocks: any;
  under_title: string;
  text_list: any;
  title: string;
}

const LessonDetail = () => {
  function scrollToBottom() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  const handleClick = (): void => {
    scrollToBottom();
  };

  const [detailsCard, setDetailCard] = useState<CardDetail>();
  const [isScrolled, setIsScrolled] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    const data = { id };

    axios
      .post(ENDPOINTS.getDetailCard, data)
      .then((response: any) => {
        console.log("Response from API:", response.data);
        setDetailCard(response.data.course);
      })
      .catch((error) => {
        console.error("Помилка відправки:", error);
      });

    const handleScroll = () => {
      if (window.scrollY > 585) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [id]);

  return (
    <div className="flex justify-center">
      <div className="max-w-[1390px] w-full px-[50px]">
        <div className="lg:flex hidden justify-between items-center mb-[90px]">
          <div className="">
            <Link to={"/lessons"} className="flex items-center gap-2">
              <img src={LeftLesson} alt="left-lesson" className="w-10" />
              <span className="font-montserrat text-[#575A5A] lg:text-[14px] text-[11px]">
                Повернутись до усіх уроків
              </span>
            </Link>
          </div>
          <div className="flex flex-col items-center w-1/2">
            <span className="text-[14px] text-[#5F5F59] mb-[12px] font-montserrat ">
              Грай, навчаючись
            </span>
            <div className="flex justify-center items-center p-[9px] h-[72px] w-[394px] bg-gradient-to-b from-[#FDE68A] to-[#FDE072] rounded-md shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)]">
              <span className="text-[40px] text-white font-black font-nunito">
                НАШІ УРОКИ
              </span>
            </div>
          </div>
          <div>
            <img src={SovaLogoLesson} alt="Sova" className="h-[150px]" />
          </div>
        </div>

        <div className="flex lg:hidden flex-col items-center mb-8">
          <div className="flex justify-between items-center mb-10 mt-10">
            <div>
              <Link to={"/lessons"} className="flex items-center gap-2">
                <img src={LeftLesson} alt="left-lesson" className="w-10" />
                <span className="font-montserrat text-[#575A5A] text-[11px]">
                  Повернутись до усіх уроків
                </span>
              </Link>
            </div>
            <img
              src={SovaLogoLesson}
              alt="Sova"
              className="w-[174px] h-[88px]"
            />
          </div>
          <div className="flex flex-col items-center w-1/2">
            <div className="flex justify-center items-center p-[9px] h-[32px] w-[224px] bg-gradient-to-b from-[#FDE68A] to-[#FDE072] rounded-md shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)]">
              <span className="text-[20px] text-white font-black font-nunito">
                НАШІ УРОКИ
              </span>
            </div>
          </div>
        </div>

        {detailsCard && (
          <div className="lg:w-full w-[348px] bg-gradient-to-b from-[#FFF3C3] to-[#FDE072] p-3 rounded-md mb-16">
            <div className="w-full bg-white rounded-md lg:p-11 p-2">
              <div className="flex justify-center lg:gap-10">
                <div className="lg:flex hidden justify-center w-1/2">
                  <img
                    src={`https://mmudryk.com.ua/${detailsCard.photo}`}
                    alt="Sova"
                    className="h-[585px] w-[full] object-contain"
                  />
                </div>
                <div>
                  <div className="flex items-center">
                    <div className="flex lg:hidden justify-center w-1/2">
                      <img
                        src={`https://mmudryk.com.ua/${detailsCard.photo}`}
                        alt="Sova"
                        className="lg:h-[585px] w-[full] object-contain"
                      />
                    </div>
                    <div className="flex lg:h-fit h-[65px] justify-center items-center bg-gradient-to-b from-[#FFF3C3] to-[#FDE072] lg:py-3 lg:px-6 rounded-md shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)]">
                      <span className="text-[#575A5A] lg:text-[48px] text-[20px] lg:leading-[48px] font-black text-center font-nunito">
                        {detailsCard.title}
                      </span>
                    </div>
                  </div>
                  <div className="mt-[17px] mb-6">
                    <span className="lg:text-[20px] text-[14px] lg:flex hidden text-[#575A5A] font-semibold font-montserrat">
                      Що входить у пакет «{detailsCard.title}»:
                    </span>
                  </div>
                  <div className="flex lg:flex-row flex-col-reverse gap-8">
                    <div className="flex lg:flex-col flex-col gap-3 lg:w-1/2">
                      {detailsCard.short_description.map(
                        (el: any, index: number) => (
                          <div className="bg-gradient-to-b from-[#EEEEEE] to-[#F3F3F3] py-2 px-3 rounded-md">
                            <span
                              className="text-[#575A5A] lg:text-[16px] text-[14px] font-semibold font-montserrat"
                              key={index}
                            >
                              {el.text}
                            </span>
                            <p className="text-[#575A5A] lg:text-[14px] text-[12px] font-montserrat">
                              {el.bold_text}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                    <div className="mt-[17px] text-center flex lg:hidden">
                      <span className="text-[14px] text-[#575A5A] font-semibold font-montserrat">
                        Що входить у пакет «{detailsCard.title}»:
                      </span>
                    </div>
                    <div className="flex lg:flex-col flex-row lg:gap-3 gap-1 justify-between">
                      <div className="bg-gradient-to-b from-[#DDDCD9] to-[#E2E2E2] rounded-md py-2 lg:px-7 px-2">
                        <span className="text-[#8E8E8E] lg:text-[16px] text-[12px] font-montserrat">
                          Вік
                        </span>
                        <p className="text-[#575A5A] lg:text-[20px] lg:text-left text-center text-[14px] font-semibold font-montserrat">
                          {detailsCard.age}
                        </p>
                      </div>
                      <div className="bg-gradient-to-b from-[#DDDCD9] to-[#E2E2E2] rounded-md py-2 lg:px-7 px-1">
                        <span className="text-[#8E8E8E] lg:text-[16px] text-[12px] font-montserrat">
                          Тривалість уроку
                        </span>
                        <p className="text-[#575A5A] lg:text-[20px] lg:text-left text-center text-[14px] font-semibold font-montserrat">
                          {detailsCard.duration} хв
                        </p>
                      </div>
                      <div className="bg-gradient-to-b from-[#DDDCD9] to-[#E2E2E2] rounded-md py-2 lg:px-7 px-0.5">
                        <span className="text-[#8E8E8E] lg:text-[16px] lg:text-left text-center text-[12px] font-montserrat">
                          Кількість дітей в групі
                        </span>
                        <p className="text-[#575A5A] lg:text-[20px] text-[14px] font-semibold font-montserrat">
                          До {detailsCard.max_members} дітей в групі
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-14 mb-5">
                    <button
                      className="bg-gradient-to-b from-[#FFF3C3] to-[#FDE072] lg:px-9 px-6 py-4 rounded-2xl shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)] active:scale-95 transition-transform duration-500"
                      onClick={handleClick}
                    >
                      <span className="text-[#575A5A] lg:text-[20px] text-[14px] font-semibold font-montserrat">
                        Записатися на пробний урок
                      </span>
                    </button>
                  </div>
                  <div className="flex flex-col gap-10">
                    {detailsCard.text_list.map((el: any, index: number) => (
                      <div key={index}>
                        <span className="text-[#494943] lg:text-[16px] text-[14px] font-bold font-montserrat">
                          {el.title}
                        </span>
                        <span className="font-montserrat">
                          <ul className="flex flex-col gap-1 mb-4">
                            {el.list_item.map(
                              (item: string, itemIndex: number) => (
                                <li
                                  key={itemIndex}
                                  className="list-disc text-[14px] lg:w-full w-11/12 lg:block flex items-center"
                                >
                                  {item}
                                </li>
                              )
                            )}
                          </ul>
                          {el.text_blocks && el.text_blocks.trim() !== "" && (
                            <div className="bg-[#F3F3F3] flex justify-center items-center h-[39px] px-[30px] rounded-md">
                              <span>{el.text_blocks}</span>
                            </div>
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-[45px]">
                <div className="lg:w-full flex flex-col items-center w-[311px] py-[14px] bg-gradient-to-b from-[#FFF3C3] to-[#FDE072] rounded-[19px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)]">
                  {detailsCard.price.map((item: any, index: number) => (
                    <span
                      className="text-[#575A5A] lg:text-[36px] text-[24px] font-bold font-nunito"
                      key={index}
                    >
                      {item.bold_text}
                    </span>
                  ))}
                  {detailsCard.price.map((item: any, index: number) => (
                    <p
                      className="text-[#575A5A] lg:text-[36px] text-[20px] text-center font-nunito"
                      key={index}
                    >
                      {item.text}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonDetail;
