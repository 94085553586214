import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import { useEffect, useState } from "react";
import Logo from "../../images/Logo.webp";
import LogoMenu from "../../images/Logo_burger.svg";
import "./style.css";
import axios from "axios";
import { ENDPOINTS } from "../../api/apiEndpoints";
interface IPropsHeader {
  setIsFadeActive: Function;
}
const Header = ({ setIsFadeActive }: IPropsHeader) => {
  const location = useLocation();
  const [isActiveMobileMenu, setIsActiveMobileMenu] = useState(false);
  const router = useNavigate();

  const scrollToTop = (location: string) => {
    return (event: any) => {
      event.preventDefault();
      setIsFadeActive(true);
      setTimeout(() => {
        setIsFadeActive(false);
        router(location);
      }, 600);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
  };

  const isActive = (path: string) => location.pathname === path;

  const handleToggleMenu = () => {
    setIsActiveMobileMenu((prev) => !prev);
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsActiveMobileMenu(false);
    };

    const unlisten = () => {
      handleRouteChange();
    };

    return () => {
      unlisten();
    };
  }, [location]);

  const [options, setOptions] = useState([]);
  useEffect(() => {
    Promise.all([axios.get(ENDPOINTS.getTeam), axios.get(ENDPOINTS.getCourses)])
      .then((responses) => {
        const teamData = responses[0].data;
        const courseData = responses[1].data;

        const teamMembersOptions = teamData.team_members.map((member: any) => ({
          label: <Link to={"/team"}>{member.name}</Link>,
          value: member.name,
        }));

        const coursesOptions = courseData.courses.map((course: any) => ({
          label: <Link to={"/lessons"}>{course.title}</Link>,
          value: course.title,
        }));

        const allOptions: any = [...teamMembersOptions, ...coursesOptions];
        setOptions(allOptions);
      })
      .catch((errors) => {
        console.error("Помилка отримання даних:", errors);
      });
  }, []);
  
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  return (
    <div className="flex justify-center bg-[#FDE6BA]">
      <div className="max-w-[1390px] w-full h-auto px-[50px] py-[36px]">
        <ul className="lg:flex items-center justify-between text-[#484C4C] text-[16px] font-montserrat hidden">
          <li>
            <NavLink to={"/"} onClick={scrollToTop("/")}>
              <img src={Logo} alt="Logo" className="w-[79px] h-[79px]" />
            </NavLink>
          </li>
          <li
            className={`relative ${
              isActive("/") &&
              "text-[#C8A664] font-semibold mt-1 border-b-4 border-[#C8A664]"
            }`}
          >
            <NavLink to={"/"} onClick={scrollToTop("/")}>
              Головна
            </NavLink>
          </li>

          <li
            className={`z-10 ${
              isActive("/team") &&
              "text-[#C8A664] font-semibold mt-1 border-b-4 border-[#C8A664]"
            }`}
          >
            <NavLink to={"/team"} onClick={scrollToTop("/team")}>
              Наша команда
            </NavLink>
          </li>
          <li
            className={`z-10 ${
              isActive("/lessons") &&
              "text-[#C8A664] font-semibold mt-1 border-b-4 border-[#C8A664]"
            }`}
          >
            <NavLink to={"/lessons"} onClick={scrollToTop("/lessons")}>
              Наші уроки
            </NavLink>
          </li>
          <li
            className={`z-10 ${
              isActive("/schedule") &&
              "text-[#C8A664] font-semibold mt-1 border-b-4 border-[#C8A664]"
            }`}
          >
            <NavLink to={"/schedule"} onClick={scrollToTop("/schedule")}>
              Розклад уроків
            </NavLink>
          </li>
          <li
            className={`z-10 ${
              isActive("/faq") &&
              "text-[#C8A664] font-semibold mt-1 border-b-4 border-[#C8A664]"
            }`}
          >
            <NavLink to={"/faq"} onClick={scrollToTop("/faq")}>
              FAQ
            </NavLink>
          </li>
          <li
            className={`z-10 ${
              isActive("/contacts") &&
              "text-[#C8A664] font-semibold mt-1 border-b-4 border-[#C8A664]"
            }`}
          >
            <NavLink to={"/contacts"} onClick={scrollToTop("/contacts")}>
              Контакти
            </NavLink>
          </li>
          <li className="z-10">
            <Select
              showSearch
              allowClear
              placeholder="Пошук..."
              style={{ width: "223px" }}
              options={options}
            />
          </li>
        </ul>

        <div className="flex justify-between lg:hidden">
          <div className="flex">
            <div className="flex items-center w-[30px] lg:w-0 z-40">
              <button
                onClick={handleToggleMenu}
                className="lg:hidden flex absolute"
              >
                <input
                  type="checkbox"
                  id="menu_checkbox"
                  className="absolute z-10 w-[30px] h-[30px]"
                  checked={isActiveMobileMenu}
                />
                <label htmlFor="menu_checkbox">
                  <div></div>
                  <div></div>
                  <div></div>
                </label>
              </button>
            </div>
          </div>
          <div>
            <Input
              size="large"
              placeholder="Пошук..."
              prefix={<SearchOutlined />}
              className=""
              type="checkbox"
              onChange={handleCheckboxChange}
            />
          </div>
        </div>
        <div
          className={`transition-all duration-400 w-full z-30 bg-white opacity-90 left-[8%] rounded-[15px] top-[30px] absolute max-w-[360px] ${
            isActiveMobileMenu ? "translate-y-0" : "translate-y-[-150%]"
          }`}
        >
          <div className="flex justify-end pr-10 pt-10">
            <img src={LogoMenu} alt="Logo_menu" />
          </div>
          <ul className="flex flex-col items-center gap-4 px-10 pb-10 text-[#484C4D] font-semibold">
            <li className={`relative ${isActive("/") && "text-[#C8A664]"}`}>
              <NavLink to={"/"} onClick={scrollToTop("/")}>
                Головна
              </NavLink>
            </li>

            <li className={`z-10 ${isActive("/team") && "text-[#C8A664]"}`}>
              <NavLink to={"/team"} onClick={scrollToTop("/team")}>
                Наша команда
              </NavLink>
            </li>
            <li className={`z-10 ${isActive("/lessons") && "text-[#C8A664]"}`}>
              <NavLink to={"/lessons"} onClick={scrollToTop("/lessons")}>
                Наші уроки
              </NavLink>
            </li>
            <li className={`z-10 ${isActive("/schedule") && "text-[#C8A664]"}`}>
              <NavLink to={"/schedule"} onClick={scrollToTop("/schedule")}>
                Розклад уроків
              </NavLink>
            </li>
            <li className={`z-10 ${isActive("/faq") && "text-[#C8A664]"}`}>
              <NavLink to={"/faq"} onClick={scrollToTop("/faq")}>
                FAQ
              </NavLink>
            </li>
            <li className={`z-10 ${isActive("/contacts") && "text-[#C8A664]"}`}>
              <NavLink to={"/contacts"} onClick={scrollToTop("/contacts")}>
                Контакти
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
