import SovaLogoLesson from "../../../images/Sova_logo_lesson.webp";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ENDPOINTS } from '../../../api/apiEndpoints';
interface LessonType {
  id: number;
  photo: string;
  title: string;
  age: number;
  duration: number;
  schedule: string;
  lesson_goal: string;
}

const Lessons = () => {
  const [lessonData, setLessonData] = useState<LessonType[]>([]);

  useEffect(() => {
    axios
      .get(ENDPOINTS.getCourses)
      .then((response) => {
        setLessonData(response.data.courses);
        console.log(response);
      })
      .catch((error) => {
        console.error("Помилка отримання даних:", error);
      });
  }, []);

  return (
    <div className="flex justify-center">
      <div className="max-w-[1390px] w-full px-[50px]">
        <div className="flex lg:flex-row flex-col-reverse lg:items-start justify-end items-center w-full mt-[70px] mb-[90px]">
          <div className="flex flex-col items-center lg:w-1/2">
            <span className="text-[14px] text-[#5F5F59] mb-[12px] font-montserrat lg:block hidden">
              Грай, навчаючись
            </span>
            <div className="flex justify-center items-center p-[9px] lg:min-h-[72px] min-h-[32px] lg:w-[394px] w-[224px] bg-gradient-to-b from-[#FDE68A] to-[#FDE072] rounded-md shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)]">
              <span className="lg:text-[40px] text-[20px] text-white font-black font-nunito">
                НАШІ УРОКИ
              </span>
            </div>
          </div>
          <div className="flex lg:w-1/4 justify-end">
            <img
              src={SovaLogoLesson}
              alt="Sova"
              className="lg:w-[300px] w-[175px] lg:h-[150px] h-[88px]"
            />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-x-[12px] lg:gap-y-[70px] gap-y-[36px] mb-[190px]">
            {lessonData.map((el, index) => (
              <div
                key={index}
                className="lg:w-[425px] w-[348px] min-h-[606px] rounded-md bg-gradient-to-b from-[#FDE790] to-[#FCE071] p-[14px]"
              >
                <div className="bg-white w-full h-full rounded-md p-[6px]">
                  <div className="flex justify-center items-center h-[54%]">
                    <div>
                      <img
                        src={`https://mmudryk.com.ua/${el.photo}`}
                        alt={` SovaLessonsImg ${el.id + 1} `}
                        className="h-[313px] object-contain"
                      />
                    </div>
                    <div className="flex justify-center items-center p-[9px] bg-gradient-to-b from-[#FFF3C3] to-[#FDE072] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)] rounded-md">
                      <span className="text-center lg:text-[24px] text-[20px] text-[#575A5A] leading-6 font-bold font-montserrat">
                        {el.title}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-x-[12px] mb-[8px]">
                    <div className="w-full p-[3px] rounded-md bg-gradient-to-b from-[#DDDCD9] to-[#E2E2E2]">
                      <div className="flex justify-center">
                        <span className="text-[12px] text-[#8E8E8E] font-montserrat">
                          Вік
                        </span>
                      </div>
                      <div className="flex justify-center">
                        <span className="font-semibold text-[16px] text-[#575A5A] font-montserrat">
                          {el.age}
                        </span>
                      </div>
                    </div>
                    <div className="w-full p-[3px] rounded-md bg-gradient-to-b from-[#DDDCD9] to-[#E2E2E2]">
                      <div className="flex justify-center">
                        <span className="text-[12px] text-[#8E8E8E] font-montserrat">
                          Тривалість
                        </span>
                      </div>
                      <div className="flex justify-center">
                        <span className="font-semibold text-[16px] text-[#575A5A] font-montserrat">
                          {el.duration} {"хв"}
                        </span>
                      </div>
                    </div>
                    <div className="w-full p-[3px] rounded-md bg-gradient-to-b from-[#DDDCD9] to-[#E2E2E2]">
                      <div className="flex justify-center">
                        <span className="text-[12px] text-[#8E8E8E] font-montserrat">
                          Графік роботи
                        </span>
                      </div>
                      <div className="flex justify-center">
                        <span className="font-semibold text-[16px] text-[#575A5A] font-montserrat">
                          {el.schedule}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gradient-to-b from-[#FFF3C3] to-[#FDE072] rounded-md shadow-[0px_4px_4px_0px_rgba(0,0,0,0.15)] px-[20px] py-[15px] max-h-[200px] h-full flex flex-col justify-between">
                    <div>
                      <span className="text-[16px] text-[#494943] font-bold font-montserrat">
                        Мета уроку:
                      </span>
                    </div>
                    <div>
                      <p className="text-[14px] text-[#5F5F59] font-medium font-montserrat">
                        {el.lesson_goal}
                      </p>
                    </div>
                    <div className="w-full flex justify-center items-end">
                      <div className="w-[143px] flex justify-center py-[6px] px-[20px] bg-gradient-to-b from-[#FFFFFF] to-[#E9E6E3] shadow-[0px_4px_7px_0px_rgba(0,0,0,0.25)] rounded-lg">
                        <Link
                          className="text-[#575A5A] text-[16px] font-semibold font-montserrat"
                          to={`/lessons/${el.id}`}
                        >
                          Детальніше
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lessons;
