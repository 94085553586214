import { Carousel } from "antd";
import Kolo1 from "../../images/Kolo1.webp";
import Kolo2 from "../../images/Kolo2.webp";
import Kolo3 from "../../images/Kolo3.webp";
import Pentagon1 from "../../images/Pentagon1.webp";
import Pentagon2 from "../../images/Pentagon2.webp";
import Pentagon3 from "../../images/Pentagon3.webp";
import SovaAdv from "../../images/Sova_adv.svg";
import ICardAdvantages from "../../interfaces/cardAdvantages.interface";
import CardAdvantages from "./cardAdvantages";

const card: ICardAdvantages[] = [
  {
    id: 0,
    title: "Використовуємо освітню систему",
    description: "Освітня програма відповідає Державному стандарту",
    img: Kolo1,
  },
  {
    id: 1,
    title: "Сучасні педагогічні методи, прийоми й інструменти",
    description:
      "Роблять уроки цікавими, пізнавальними та яскравими. Спрощують запам’ятовування фактів, граматичних правил. Мінімум домашніх завдань – максимум користі від кожного заняття",
    img: Kolo2,
  },
  {
    id: 2,
    title: "Групове або індивідуальне навчання",
    description:
      "Можна приєднатись до групи з 10 дітей, малої групи – 3-4 дітей або замовити індивідуальні репетиторські послуги",
    img: Kolo3,
  },
  {
    id: 3,
    title: "Працюємо у дві зміни",
    description:
      "Наші педагоги проводять консультації і в першу, і в другу зміну (за київським часом)",
    img: Pentagon1,
  },
  {
    id: 4,
    title: "Багатий досвід дистанційного навчання",
    description:
      "Наші вчителі мають багатий досвід дистанційної роботи й легко адаптуються до нестандартних ситуацій. Вчителі та репетитори проходять ліцензовані курси підвищення кваліфікації кожного року",
    img: Pentagon2,
  },
  {
    id: 5,
    title: "Дбаємо про здоров’я школярів",
    description:
      "Кожного уроку робимо з дітьми спеціальні вправи для очей, пальчиків та хребта",
    img: Pentagon3,
  },
];

const Advantages = () => {
  return (
    <div className="flex justify-center flex-col mb-[110px] z-50">
      <div className="flex justify-center mt-[70px] mb-[36px]">
        <h1
          className="lg:text-[40px] text-[24px] text-[#FFFFFF] text-center font-black font-nunito"
          id="text"
        >
          НАШІ ПЕРЕВАГИ
        </h1>
      </div>
      <div className="lg:grid hidden lg:grid-rows-3 lg:grid-flow-col items-center justify-center gap-y-10 gap-x-[300px] relative z-20">
        {card.map((el) => (
          <CardAdvantages cardData={el} key={el.id} />
        ))}
        <div className="absolute top-[10%] right-[450px] z-[-1]">
          <img src={SovaAdv} alt="Sova_adv" className="h-[562px]" />
        </div>
      </div>
      <div className="lg:hidden">
        <Carousel autoplay>
          {card.map((el) => (
            <div className="m-12 max-w-[233px] w-full !h-[217px]">
              <div className="flex bg-white shadow-[0px_3px_15px_0px_rgba(0,0,0,0.25)] w-[233px] h-[217px] rounded-[10px] py-4 px-[14px] gap-x-5 justify-center">
                <div className="flex flex-col items-center">
                  <div className="bg-[#FDE790] w-[192px] h-[49px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.25)] rounded-md pt-[7px] pb-[9px] pl-[7px] flex items-center mb-5">
                    <span className="text-[#5F5F59] text-[14px] font-bold font-montserrat leading-4 text-center">
                      {el.title}
                    </span>
                  </div>
                  <div>
                    <p className="text-[#5F5F59] text-[12px] font-montserrat font-medium leading-4">
                      {el.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Advantages;
